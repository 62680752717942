<template>
  <el-scrollbar :native="false">
    <div
      v-if="formAction"
      style="padding: 20px; max-height: calc(100vh - 40px)"
    >
      <div class="titles">{{ __("Pool Name") }}</div>
      <div class="details">{{ contentFormInfo.pool_name }}</div>
      <div class="titles">{{ __("Total Numbers") }}</div>
      <div class="details">{{ contentFormInfo.total_numbers_count }}</div>
      <div class="titles">{{ __("Available Numbers") }}</div>
      <div class="details">{{ contentFormInfo.available_numbers_count }}</div>
      <div style="display: flex; margin-bottom: 40px">
        <el-dropdown @command="handlePoolAction" class="poolActionDropDown">
          <el-button type="primary">
            {{ __("Actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              :disabled="
                command.permission === 'write' ? !canWrite() : !canRead()
              "
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-dialog
        :visible.sync="openModal"
        v-if="openModal"
        :show-close="true"
        :before-close="notifyClosingOfModal"
        custom-class="editContentItemModal"
        destroy-on-close
        fullscreen
        :modal="false"
      >
        <component
          :dnispool="contentForm"
          :poolInfo="contentFormInfo"
          v-bind:is="component"
          :id="id"
          @cancel="handleCancel"
        ></component>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import { mapActions } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import Copier from "@/components/Copier";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  name: "PoolActions",
  components: {
    Copier,
    PageHeader
  },
  data() {
    return {
      commands: [
        {
          command: "createOrEditForm",
          rules: {},
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit"),
          permission: "write"
        },
        {
          command: "viewData",
          rules: {},
          icon: "campaign_actions/icon_view_data.svg",
          label: __("View Data"),
          permission: "read"
        },
        {
          command: "delete",
          rules: {},
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Delete"),
          permission: "write"
        }
      ],
      rules: {},
      selectedAction: "createOrEditForm"
    };
  },
  methods: {
    ...mapActions("dnispool", {
      createDnisPool: "createDnisPool",
      updateDnisPool: "updateDnisPool",
      setSelectedPoolId: "setSelectedPoolId",
      deleteContentMethod: "deleteDnisPool",
      undoDeleteContent: "undoDeleteDnisPool"
    }),
    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },
    handlePoolAction(action) {
      if (action === "delete") {
        this.handleDelete(this.contentForm);
      } else {
        if (["createOrEditForm"].includes(action)) {
          this.$emit("edit-item");
        } else if (["viewData"].includes(action)) {
          this.$emit("extend-item");
        }
        this.selectedAction = action;
        this.handleOpenModal(true);
      }
    },
    importComponent(path) {
      return () => import("@/views/manage/dnis-pool/pages/" + path);
    }
  },
  computed: {
    component() {
      return this.importComponent(this.selectedAction);
    },
    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return rule.includes(this.contentForm[key].toLowerCase());
        });
      });
    }
  },
  watch: {
    contentForm: {
      handler: function(pool) {
        this.setSelectedPoolId(pool.pool_id);
      }
    },
    formAction: {
      immediate: true,
      handler: function(val) {
        if (val) {
          if (val === "edit" || val === "add") {
            this.selectedAction = "createOrEditForm";
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

.poolActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
